import { css } from '@emotion/react';
import { Box } from '@mui/material';
import { Formik } from 'formik';
import countBy from 'lodash/countBy';
import isEmpty from 'lodash/isEmpty';
import partition from 'lodash/partition';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import React from 'react';

import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { FrontEndCarrierReadByUser } from '@headway/api/models/FrontEndCarrierReadByUser';
import { MarketRead } from '@headway/api/models/MarketRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { SpecialtyApi } from '@headway/api/resources/SpecialtyApi';
import { ProviderSearchExperimentCareNavigatorModalViewedEvent } from '@headway/avo';
import { useFlag } from '@headway/feature-flags/react';
import {
  BCBS_IDS,
  CARRIERS_SUPPRESSING_TRIAGE_MODALS,
} from '@headway/shared/constants/carrierIds';
import { CARRIERS_REQUIRING_AUTHORIZATION } from '@headway/shared/constants/carrierIds';
import unitedStatesDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import {
  ANTHEM_PATIENT_FACING_CARRIER_CONSOLIDATION,
  STEP_DOWN_MODAL_EXPERIMENT_CALIFORNIA,
  STEP_DOWN_MODAL_EXPERIMENT_PENNSYLVANIA,
  STEP_UP_MODAL_EXPERIMENT_NEW_YORK,
} from '@headway/shared/FeatureFlags/flagNames';
import { useLocalStorage } from '@headway/shared/hooks/useLocalStorage';
import { useQuery } from '@headway/shared/react-query';
import { trackEvent } from '@headway/shared/utils/analytics';
import {
  convertCarrierIdstoPatientSearchableCarrierIds,
  convertCarrierIdToPatientSearchableCarrierId,
  isCarrierInHeadwayNetworkForState,
  stateHasMultipleBCBSPlan,
} from '@headway/shared/utils/carriers';
import { isFrontEndCarrierIdBcbs } from '@headway/shared/utils/insuranceUtils';
import {
  Filters,
  filterUnavailableSpecialtyKeys,
  getAvailableSpecialtiesForState,
} from '@headway/shared/utils/providerSearchFilters';
import { logException } from '@headway/shared/utils/sentry';
import {
  PatientVisibleSpecialtyRead,
  selectPatientVisibleSpecialties,
  sortByPatientDisplayName,
} from '@headway/shared/utils/specialties';
import { Button as LegacyButton } from '@headway/ui';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { useMarkets } from '@headway/ui/providers/MarketProvider';
import { theme as legacyTheme } from '@headway/ui/theme';
import { notifyError, notifyWarning } from '@headway/ui/utils/notify';

import { useRouter } from '../../hooks/useRouter';
import { useSearchableFrontEndCarriersQuery } from '../../hooks/useSearchableFrontEndCarriersQuery';
import { withStores, WithStoresChildProps } from '../../stores/withStores';
import { getProvidersSearchPath } from '../../utils/search';
import {
  LandingPageInteraction,
  SearchEntry,
  trackProviderSearchCompleted,
  trackProviderSearchConfigured,
  trackProviderSearchStarted,
} from '../../utils/searchAnalyticEvents';
import { CareNavigatorModal } from '../CareNavigatorModal/CareNavigatorModal';
import {
  shouldShowStepDownModal,
  shouldShowStepUpModal,
} from '../CareNavigatorModal/helpers';
import CustomFieldSelectWithLabel from '../CustomFieldSelect/CustomFieldSelectWithLabel';
import {
  DropdownAutocomplete,
  NO_OPTIONS_FOUND_VALUE,
} from '../DropdownAutocomplete';
import { BlueCardConfirmationModal } from './BlueCardConfirmationModal';
import { NavigationConfirmationModal } from './NavigationConfirmationModal';
import UnavailableFieldMailingListModal from './UnavailableFieldMailingListModal';

const containerStyle = css`
  display: flex;
  background-color: white;
  align-self: center;
  border-radius: 8px;
  border: 1px solid ${legacyTheme.color.lightGray};

  .MuiFormControl-root {
    margin-bottom: 0;
  }

  &:focus {
    border: 1px solid ${legacyTheme.color.primary};
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const boxStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: ${legacyTheme.space.xs2};
  @media (max-width: 768px) {
    padding-left: 0;
    margin-left: ${legacyTheme.space.sm};
    margin-right: ${legacyTheme.space.sm};
  }
`;
const dividerStyle = css`
  background-color: ${legacyTheme.color.lightGray};
  width: 1px;
  height: 55%;
  align-self: center;
  @media (max-width: 768px) {
    width: 100%;
    height: 1px;
  }
`;
const buttonStyle = css`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  flex-shrink: 0;
  @media (max-width: 768px) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    margin: 8px;
  }
`;
const customSelectStyle = css`
  .MuiSelect-select {
    border: none !important;

    &:hover {
      background-color: initial;
    }
  }
`;

const ctaButtonCopy = 'Find care';
const multipleBCBSCopy =
  'You live in a state with multiple Blue Cross Blue Shield plans, and only some of these plans accept BlueCard. Before you book a session, we recommend checking your coverage for specific providers by calling the number on the back of your insurance card.';

interface EmployerRestrictions {
  defaultCarrierIds: number[];
  stateOverrides: [
    {
      state: string;
      carrierId: number;
    },
  ];
}

interface Props extends WithStoresChildProps {
  // Builder.io Props
  //
  // See ProviderSearchCTA in pages/m/[[...page]].tsx.
  skipBlueCardPrompt?: boolean;
  skipStepDownPrompt?: boolean;
  alphabetizeStatesDropdown?: boolean;
  hideInsuranceDropdown?: boolean;
  restrictCarriersForEmployer?: boolean;
  defaultCarrierIdsForEmployer?: { carrierId: number }[];
  carrierStateOverridesForEmployer?: [{ state: string; carrierId: number }];
  states?: [{ state: UnitedStates }]; // annoying, but Builder doesn't let us do a UnitedStates[] so we have to nest it in an object

  // Non-Builder.io Props
  shouldShowAnthemEAPExperience?: boolean;
}

const ALL_UNITED_STATES_STATES = Object.values(UnitedStates).map((state) => ({
  state,
}));

const GetStartedContainer: React.FC<Props> = ({
  AuthStore,
  skipBlueCardPrompt,
  alphabetizeStatesDropdown = true,
  hideInsuranceDropdown,
  restrictCarriersForEmployer,
  defaultCarrierIdsForEmployer,
  carrierStateOverridesForEmployer,
  shouldShowAnthemEAPExperience,
  states = ALL_UNITED_STATES_STATES,
}) => {
  const isAnthemPatientFacingCarrierConsolidationEnabled = useFlag(
    ANTHEM_PATIENT_FACING_CARRIER_CONSOLIDATION
  );
  // if you delete all the state options from Builder.io, it will send an empty array 😡
  if (states.length === 0) {
    states = ALL_UNITED_STATES_STATES;
  }

  const { marketsByState } = useMarkets();
  const router = useRouter();

  const specialtiesQuery = useQuery(
    ['patient-visible-specialties'],
    () =>
      SpecialtyApi.getSpecialties({
        get_available_to_patients_only: true,
      }),
    {
      select: (specialties) => {
        const patientVisibleSpecialties =
          selectPatientVisibleSpecialties(specialties);
        return sortByPatientDisplayName(patientVisibleSpecialties);
      },
      onError(err: Error) {
        notifyWarning(
          'Error when loading specialties for filters. Please refresh and try again.'
        );
        logException(err);
      },
      staleTime: Infinity,
    }
  );

  const frontEndCarriersQuery = useSearchableFrontEndCarriersQuery({
    select: (
      carriers: Array<FrontEndCarrierRead> | Array<FrontEndCarrierReadByUser>
    ) => {
      const innCarriers = carriers.filter((c) => {
        return !c.fullyOutOfNetwork;
      });
      const [other, ...sorted] = sortBy(innCarriers, ['id', 'name']);
      return [...sorted, other];
    },
    onError(err: unknown) {
      notifyError(
        'There was a problem fetching the list of carriers. Please try again.'
      );
      logException(err);
    },
  });
  const insuranceCarriers = frontEndCarriersQuery.data;

  const specialties: PatientVisibleSpecialtyRead[] =
    specialtiesQuery.data || [];

  // we `uniq` because `states` can be set by Builder.io, which does not allow us to prevent duplicates
  const statesToDisplay = uniq(states.map((stateOption) => stateOption.state));
  const statesToExclude = [
    UnitedStates.PUERTO_RICO,
    UnitedStates.VIRGIN_ISLANDS,
  ];
  const markets = isEmpty(marketsByState) // marketsByState is empty while the data is loading
    ? []
    : statesToDisplay
        .filter((state) => !statesToExclude.includes(state))
        .map((state) => marketsByState[state]!);

  const alphabeticalMarkets = sortBy(markets, 'displayName');

  const [liveMarkets, otherMarkets] = partition(
    alphabetizeStatesDropdown ? alphabeticalMarkets : markets,
    (m) => m.marketplaceLive
  );

  const employerRestrictions = React.useMemo(() => {
    if (
      !restrictCarriersForEmployer ||
      !defaultCarrierIdsForEmployer?.length ||
      !carrierStateOverridesForEmployer
    )
      return;

    return {
      defaultCarrierIds: convertCarrierIdstoPatientSearchableCarrierIds(
        defaultCarrierIdsForEmployer.map(({ carrierId }) => carrierId),
        isAnthemPatientFacingCarrierConsolidationEnabled
      ),
      stateOverrides: carrierStateOverridesForEmployer?.map(
        ({ carrierId, ...stateOverride }) => ({
          ...stateOverride,
          carrierId: convertCarrierIdToPatientSearchableCarrierId(
            carrierId,
            isAnthemPatientFacingCarrierConsolidationEnabled
          ),
        })
      ),
    } as EmployerRestrictions;
  }, [
    restrictCarriersForEmployer,
    defaultCarrierIdsForEmployer,
    carrierStateOverridesForEmployer,
  ]);

  // We can only hide the dropdown if there is exactly one choice of insurance
  // per state.
  const shouldHideInsuranceDropdown = React.useMemo(() => {
    if (shouldShowAnthemEAPExperience) return true;
    if (!hideInsuranceDropdown) return false;
    if (!employerRestrictions) return false;

    // There must be exactly one default carrier id.
    if (employerRestrictions.defaultCarrierIds.length !== 1) return false;

    // And exactly one carrier id for each state override.
    const stateOverrideCounts = countBy(
      employerRestrictions.stateOverrides,
      'state'
    );
    return !Object.values(stateOverrideCounts).some((c) => c > 1);
  }, [
    hideInsuranceDropdown,
    employerRestrictions,
    shouldShowAnthemEAPExperience,
  ]);

  const [firstTouch, setFirstTouch] = React.useState(true);
  const [isConcernsDropdownOpened, setIsConcernsDropdownOpened] =
    React.useState(false);
  const [isStatesDropdownOpened, setIsStatesDropdownOpened] =
    React.useState(false);
  const [isCarriersDropdownOpened, setIsCarriersDropdownOpened] =
    React.useState(false);
  const [selectedNotLaunchedState, setSelectedNotLaunchedState] =
    React.useState<MarketRead | null>(null);
  const [selectedUnavailableCarrier, setSelectedUnavailableCarrier] =
    React.useState<FrontEndCarrierRead | null>(null);
  const [selectedConcerns, setSelectedConcerns] = React.useState<string[]>([]);
  const [selectedInsurance, setSelectedInsurance] = React.useState<
    number | undefined
  >(undefined);
  const [selectedState, _setSelectedState] = React.useState<
    UnitedStates | undefined
  >(undefined);
  const setSelectedState = React.useMemo(() => {
    return function setSelectedState(newState?: UnitedStates) {
      _setSelectedState(newState);
      if (employerRestrictions) {
        const carriers = carriersForEmployerInState(
          employerRestrictions,
          isAnthemPatientFacingCarrierConsolidationEnabled,
          insuranceCarriers,
          newState
        );

        if (carriers?.length === 1) {
          setSelectedInsurance(carriers[0].id);
        }
      }
    };
  }, [
    isAnthemPatientFacingCarrierConsolidationEnabled,
    _setSelectedState,
    employerRestrictions,
    insuranceCarriers,
    setSelectedInsurance,
  ]);
  const [blueCardConfirmRedirect, setBlueCardConfirmRedirect] =
    React.useState<string>('');
  const [careNavModalDismissRedirect, setCareNavModalDismissRedirect] =
    React.useState<string>('');
  const [isBlueCardModalOpen, setIsBlueCardModalOpen] = React.useState(false);
  const [isCareNavigatorModalOpen, setIsCareNavigatorModalOpen] =
    React.useState(false);
  const [careNavigationModalVariant, setCareNavigationModalVariant] =
    React.useState<'stepDown' | 'stepUp'>('stepDown');
  const [isMultipleBCBSPlanState, setIsMultipleBCBSPlanState] =
    React.useState(false);

  const [stepDownModalViewed, setStepDownModalViewed] =
    useLocalStorage(`stepDownModalViewed`);
  const [stepUpModalViewed, setStepUpModalViewed] =
    useLocalStorage(`stepUpModalViewed`);

  const fireFirstTouchTrackingEvent = (interaction: LandingPageInteraction) => {
    setFirstTouch(false);
    trackProviderSearchStarted({
      context: { interaction, source: SearchEntry.LandingPageGetStartedCta },
    });
  };

  const fireConfigureTrackingEventIfNeeded = () => {
    if (
      selectedConcerns &&
      selectedConcerns.length > 0 &&
      selectedState &&
      selectedInsurance
    ) {
      trackProviderSearchConfigured({
        filters: {
          state: selectedState,
          issues: selectedConcerns,
          frontEndCarrierId: selectedInsurance,
        },
      });
    }
  };

  const handleCloseConcernsDropdown = () => {
    setIsConcernsDropdownOpened(false);
    fireConfigureTrackingEventIfNeeded();
  };

  const handleOpenConcernsDropdown = () => {
    setIsConcernsDropdownOpened(true);
    if (firstTouch) {
      fireFirstTouchTrackingEvent(LandingPageInteraction.Concerns);
    }
  };

  const handleCloseStatesDropdown = () => {
    setIsStatesDropdownOpened(false);
    fireConfigureTrackingEventIfNeeded();
  };

  const handleOpenStatesDropdown = () => {
    setIsStatesDropdownOpened(true);
    if (firstTouch) {
      fireFirstTouchTrackingEvent(LandingPageInteraction.Location);
    }
  };

  const handleCloseCarriersDropdown = () => {
    setIsCarriersDropdownOpened(false);
    fireConfigureTrackingEventIfNeeded();
  };

  const handleOpenCarriersDropdown = () => {
    setIsCarriersDropdownOpened(true);
  };

  const isBcbsCarrierAndBlueCard = ({
    carrier,
    isPatientUsingBlueCard,
  }: {
    carrier: FrontEndCarrierRead;
    isPatientUsingBlueCard: boolean;
  }): boolean => {
    return isFrontEndCarrierIdBcbs(carrier.id) && isPatientUsingBlueCard;
  };

  const getFrontEndCarrier = (
    selectedInsurance: number
  ): FrontEndCarrierRead | undefined => {
    return insuranceCarriers
      ? insuranceCarriers.find((item) => item.id === selectedInsurance)
      : undefined;
  };

  const handleCloseBlueCardModal = () => {
    setBlueCardConfirmRedirect('');
    setIsBlueCardModalOpen(false);
  };

  const isFormFocused =
    isConcernsDropdownOpened ||
    isStatesDropdownOpened ||
    isCarriersDropdownOpened;

  const liveMarketOptions = liveMarkets.map((market) => {
    return {
      key: market.state as string,
      value: market.state as string,
      text: market.displayName,
      dataTestId: `location-select-${market.state}`,
    };
  });

  const otherMarketOptions =
    otherMarkets.length > 0
      ? [
          {
            disabled: true,
            value: 'Coming Soon!',
            key: 'coming-soon',
            text: 'Coming Soon!',
          },
          ...otherMarkets.map((market) => ({
            key: market.state,
            value: market.state,
            text: market.displayName,
          })),
        ]
      : [];

  const stepDownModalFlagEnabledCalifornia = useFlag(
    STEP_DOWN_MODAL_EXPERIMENT_CALIFORNIA,
    false
  );
  const stepDownModalFlagEnabledPennsylvania = useFlag(
    STEP_DOWN_MODAL_EXPERIMENT_PENNSYLVANIA,
    false
  );
  const stepUpModalFlagEnabledNewYork = useFlag(
    STEP_UP_MODAL_EXPERIMENT_NEW_YORK,
    false
  );

  return (
    <Formik
      initialValues={{
        market: undefined as UnitedStates | undefined,
        concerns: [],
        insurance: undefined as number | undefined,
      }}
      onSubmit={async () => {
        if (selectedState) {
          trackProviderSearchCompleted({
            filters: {
              state: selectedState,
              issues: selectedConcerns,
              frontEndCarrierId: selectedInsurance,
            },
            context: {
              source: SearchEntry.LandingPageGetStartedCta,
              ctaText: ctaButtonCopy,
            },
          });

          const market_ =
            typeof selectedState === 'object'
              ? (selectedState as MarketRead)
              : (marketsByState[selectedState] as MarketRead);

          if (market_) {
            if (!market_.marketplaceLive) {
              setSelectedNotLaunchedState(market_);
              return;
            }

            const searchFilters: Partial<Filters> = {
              issues: selectedConcerns,
            };
            if (selectedInsurance) {
              const frontEndCarrier = getFrontEndCarrier(selectedInsurance);
              if (!frontEndCarrier) {
                notifyError('Error selecting insurance. Please try again.');
                logException(
                  new Error(
                    'No front end carrier found for selected insurance'
                  ),
                  { extra: { selectedInsurance } }
                );
                return;
              }

              if (
                isCarrierInHeadwayNetworkForState(
                  frontEndCarrier,
                  market_.state
                )
              ) {
                searchFilters.frontEndCarrierId = selectedInsurance;
              } else if (
                isBcbsCarrierAndBlueCard({
                  carrier: frontEndCarrier,
                  isPatientUsingBlueCard: true, // TODO: make this use a real value
                }) &&
                BCBS_IDS.includes(frontEndCarrier.id)
              ) {
                searchFilters.frontEndCarrierId = selectedInsurance;
                searchFilters.initialFrontEndCarrierId = selectedInsurance;
                searchFilters.isBlueCard = true;

                const searchPath = getProvidersSearchPath({
                  marketSlug: market_.slug,
                  filters: searchFilters,
                });
                setBlueCardConfirmRedirect(searchPath);

                if (skipBlueCardPrompt) {
                  router.push(searchPath);
                } else {
                  setIsBlueCardModalOpen(true);
                }
                return;
              } else {
                setSelectedUnavailableCarrier(frontEndCarrier);
                return;
              }
            }

            const stepDownModalNotYetShown = stepDownModalViewed !== 'true';
            const isCarrierRequiringAuthorizationSelected = !!(
              selectedInsurance &&
              CARRIERS_SUPPRESSING_TRIAGE_MODALS.includes(selectedInsurance)
            );
            if (
              stepDownModalNotYetShown &&
              shouldShowStepDownModal(
                market_.state,
                selectedConcerns,
                stepDownModalFlagEnabledCalifornia,
                stepDownModalFlagEnabledPennsylvania,
                isCarrierRequiringAuthorizationSelected
              )
            ) {
              setCareNavigationModalVariant('stepDown');
              setCareNavModalDismissRedirect(
                getProvidersSearchPath({
                  marketSlug: market_.slug,
                  filters: searchFilters,
                })
              );
              setIsCareNavigatorModalOpen(true);
              setStepDownModalViewed('true');
              trackEvent({
                name: 'Provider Search Experiment Care Navigator Modal Viewed',
                properties: {
                  variantName: 'stepDown',
                },
              } as ProviderSearchExperimentCareNavigatorModalViewedEvent);
              return;
            }

            const stepUpModalNotYetShown = stepUpModalViewed !== 'true';
            if (
              stepUpModalNotYetShown &&
              shouldShowStepUpModal(
                market_.state,
                selectedConcerns,
                stepUpModalFlagEnabledNewYork
              )
            ) {
              setCareNavigationModalVariant('stepUp');
              setCareNavModalDismissRedirect(
                getProvidersSearchPath({
                  marketSlug: market_.slug,
                  filters: searchFilters,
                })
              );
              setIsCareNavigatorModalOpen(true);
              setStepUpModalViewed('true');
              trackEvent({
                name: 'Provider Search Experiment Care Navigator Modal Viewed',
                properties: {
                  variantName: 'stepUp',
                },
              } as ProviderSearchExperimentCareNavigatorModalViewedEvent);
              return;
            }

            const link = getProvidersSearchPath({
              marketSlug: market_.slug,
              filters: searchFilters,
            });

            trackEvent({
              name: 'go-to-search-results',
              properties: {
                patientUserId: AuthStore.user.id,
                lighthouseFlow: false,
              },
            });
            router.push(link);
          }
        } else {
          handleOpenStatesDropdown();
        }
      }}
    >
      {({ isSubmitting, setFieldValue, values, dirty }) => {
        return (
          <React.Fragment>
            <SafeFormikForm
              css={[
                containerStyle,
                isFormFocused
                  ? { border: `solid 1px ${legacyTheme.color.primary}` }
                  : { border: `solid 1px ${legacyTheme.color.lightGray}` },
              ]}
            >
              <CustomFieldSelectWithLabel
                name="market"
                testId="location-select"
                label="Location"
                placeholder="Where do you live?"
                onClose={handleCloseStatesDropdown}
                onOpen={handleOpenStatesDropdown}
                open={isStatesDropdownOpened}
                setFieldValue={setFieldValue}
                initialValue={undefined}
                onSelect={(state: UnitedStates) => {
                  const availableSelectedConcerns =
                    filterUnavailableSpecialtyKeys(
                      specialties,
                      selectedConcerns,
                      state
                    );
                  setSelectedConcerns(availableSelectedConcerns);
                  setFieldValue('concerns', availableSelectedConcerns);
                  setSelectedState(state);
                }}
                renderValue={(selected: any) =>
                  unitedStatesDisplayNames[selected as UnitedStates]
                }
                value={selectedState}
                options={[...liveMarketOptions, ...otherMarketOptions]}
                values={values}
                inputStyle={customSelectStyle}
                css={{
                  width: `200px !important`,
                  marginLeft: legacyTheme.space.xs,
                  paddingTop: legacyTheme.space.xs,
                  paddingBottom: legacyTheme.space.xs,
                  [legacyTheme.media.smallDown]: {
                    width: `100% !important`,
                    alignSelf: 'stretch',
                    marginLeft: 0,
                    paddingTop: legacyTheme.space.sm,
                    paddingBottom: legacyTheme.space.sm,
                  },
                }}
              />
              <Box css={boxStyle}>
                <div css={dividerStyle} />
              </Box>
              <CustomFieldSelectWithLabel
                name="concerns"
                testId="compass-concerns"
                label="Concerns"
                multiple
                values={values}
                dirty={dirty}
                placeholder="What can we help with?"
                onClose={handleCloseConcernsDropdown}
                onOpen={handleOpenConcernsDropdown}
                open={isConcernsDropdownOpened}
                onSelect={(concerns: string[]) => setSelectedConcerns(concerns)}
                setFieldValue={setFieldValue}
                initialValue={[]}
                shareLater
                renderValue={(key: string) =>
                  specialties?.find((item) => item.key === key)
                    ?.patientDisplayName
                }
                value={selectedConcerns}
                options={getAvailableSpecialtiesForState(
                  specialties,
                  selectedState
                ).map((specialty) => {
                  return {
                    key: specialty.key,
                    value: specialty.key,
                    text: specialty.patientDisplayName,
                  };
                })}
                css={{
                  width: `190px !important`,
                  marginLeft: legacyTheme.space.xs,
                  paddingTop: legacyTheme.space.xs,
                  paddingBottom: legacyTheme.space.xs,
                  [legacyTheme.media.smallDown]: {
                    width: `100% !important`,
                    alignSelf: 'stretch',
                    marginLeft: 0,
                    paddingTop: legacyTheme.space.sm,
                    paddingBottom: legacyTheme.space.sm,
                  },
                }}
                inputStyle={customSelectStyle}
              />
              <Box css={boxStyle}>
                <div css={dividerStyle} />
              </Box>
              {!shouldHideInsuranceDropdown && (
                <InsuranceCarrierDropdown
                  values={values}
                  dirty={dirty}
                  open={isCarriersDropdownOpened}
                  onOpen={handleOpenCarriersDropdown}
                  onClose={handleCloseCarriersDropdown}
                  setSelectedInsurance={setSelectedInsurance}
                  selectedInsurance={selectedInsurance}
                  setFieldValue={setFieldValue}
                  insuranceCarriers={insuranceCarriers}
                  openStatesDropdown={handleOpenStatesDropdown}
                  isFormFocused={isFormFocused}
                  selectedState={selectedState}
                  employerRestrictions={employerRestrictions}
                />
              )}
              <Box
                css={[
                  boxStyle,
                  {
                    display: 'none',
                    '@media (max-width: 768px)': {
                      display: 'flex',
                    },
                  },
                ]}
              >
                <div css={dividerStyle} />
              </Box>
              <LegacyButton
                type="submit"
                css={buttonStyle}
                color="primary"
                variant="contained"
                disabled={isSubmitting}
                size="large"
                data-testid="find-care-button"
                className={'ld-landing-page-find-providers-btn'} // for AB testing metrics
              >
                {ctaButtonCopy}
              </LegacyButton>
            </SafeFormikForm>
            <UnavailableFieldMailingListModal
              isOpen={!!selectedNotLaunchedState}
              close={() => setSelectedNotLaunchedState(null)}
              title="We can't serve you, yet."
              description="But let’s keep in touch — you’ll be the first to know when we can take your insurance in your area."
              onSuccessfulSubmit={(email) => {
                // TODO (soleil/sc-124213): find new place for this
                // return trackEvent({
                //   name: 'Out of state email collection',
                //   properties: {
                //     patientUserId: AuthStore.user.id,
                //     patientEmail: email,
                //     marketRequested: selectedNotLaunchedState?.displayName,
                //   },
                // });
              }}
            />
            <UnavailableFieldMailingListModal
              isOpen={!!selectedUnavailableCarrier}
              close={() => setSelectedUnavailableCarrier(null)}
              title="We can't serve you, yet."
              description="But let’s keep in touch — you’ll be the first to know when we can take your insurance in your area."
              onSuccessfulSubmit={(email: string) => {
                // TODO (soleil/sc-124213): find new place for this
                // return trackEvent({
                //   name: 'Unavailable carrier email collection',
                //   properties: {
                //     patientUserId: AuthStore.user.id,
                //     patientEmail: email,
                //     marketRequested: selectedUnavailableCarrier?.name,
                //   },
                // });
              }}
            />
            <NavigationConfirmationModal
              isOpen={isMultipleBCBSPlanState}
              title="Check your benefits before booking"
              description={multipleBCBSCopy}
              buttonText="Got it"
              handleOnClick={() => router.push(blueCardConfirmRedirect)}
              onClose={() => setIsMultipleBCBSPlanState(false)}
            />
            <CareNavigatorModal
              isOpen={isCareNavigatorModalOpen}
              variant={careNavigationModalVariant}
              onClose={(shouldRedirectToSearchResults) => {
                setIsCareNavigatorModalOpen(false);
                if (shouldRedirectToSearchResults) {
                  router.push(careNavModalDismissRedirect);
                }
              }}
            />
            <BlueCardConfirmationModal
              isOpen={isBlueCardModalOpen}
              onClose={handleCloseBlueCardModal}
              onSubmit={(value) => {
                /*
                  if yes, then you push to the saved redirect link
                  if no, we open the unavailable carrier modal
                */

                if (value === 'yes') {
                  if (
                    selectedState &&
                    stateHasMultipleBCBSPlan(selectedState)
                  ) {
                    setIsMultipleBCBSPlanState(true);
                    setIsBlueCardModalOpen(false);
                  } else {
                    trackEvent({
                      name: 'Completed BlueCard Confirmation Flow',
                      properties: {
                        patientUserId: AuthStore.user.id,
                      },
                    });
                    router.push(blueCardConfirmRedirect);
                  }
                } else if (value === 'no') {
                  if (selectedInsurance) {
                    const frontEndCarrier =
                      getFrontEndCarrier(selectedInsurance) ?? null;
                    setSelectedUnavailableCarrier(frontEndCarrier);
                  }
                  handleCloseBlueCardModal();
                }
              }}
            />
          </React.Fragment>
        );
      }}
    </Formik>
  );
};

function carriersForEmployerInState(
  employerRestrictions: EmployerRestrictions,
  isAnthemPatientFacingCarrierConsolidationEnabled: boolean,
  searchableCarriers?: FrontEndCarrierRead[],
  selectedState?: UnitedStates
): FrontEndCarrierRead[] | undefined {
  if (selectedState) {
    const foundCarriers = employerRestrictions?.stateOverrides
      .filter(({ state }) => state === selectedState)
      .map(
        ({ carrierId: employerRestrictionCarrierId }) =>
          searchableCarriers?.find(
            ({ id }) =>
              id ===
              convertCarrierIdToPatientSearchableCarrierId(
                employerRestrictionCarrierId,
                isAnthemPatientFacingCarrierConsolidationEnabled
              )
          )
      )
      .filter((c) => !!c) as FrontEndCarrierRead[];
    if (foundCarriers.length) return foundCarriers;
  }
  const searchableEmployerRestrictionDefaultCarrierIds =
    convertCarrierIdstoPatientSearchableCarrierIds(
      employerRestrictions.defaultCarrierIds,
      isAnthemPatientFacingCarrierConsolidationEnabled
    );
  return searchableCarriers
    ?.filter(({ id }) =>
      searchableEmployerRestrictionDefaultCarrierIds.includes(id)
    )
    .filter((c) => !!c) as FrontEndCarrierRead[];
}

interface InsuranceCarrierDropdownProps {
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  values: {
    market?: UnitedStates;
    concerns: any[];
    insurance?: number;
  };
  dirty: boolean;
  setSelectedInsurance: (newInsurance?: number) => void;
  selectedInsurance?: number;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  insuranceCarriers?: FrontEndCarrierRead[];
  openStatesDropdown: () => void;
  isFormFocused: boolean;
  selectedState?: UnitedStates;
  employerRestrictions?: EmployerRestrictions;
}

const InsuranceCarrierDropdown: React.FC<InsuranceCarrierDropdownProps> = ({
  open,
  onOpen,
  onClose,
  values,
  dirty,
  setSelectedInsurance,
  selectedInsurance,
  setFieldValue,
  insuranceCarriers,
  openStatesDropdown,
  isFormFocused,
  selectedState,
  employerRestrictions,
}) => {
  const typesearchEnabled = useFlag('patientTypesearchInsurance', false);
  const isAnthemPatientFacingCarrierConsolidationEnabled = useFlag(
    ANTHEM_PATIENT_FACING_CARRIER_CONSOLIDATION
  );

  const optionSections = React.useMemo(() => {
    if (employerRestrictions) {
      const carriers = carriersForEmployerInState(
        employerRestrictions,
        isAnthemPatientFacingCarrierConsolidationEnabled,
        insuranceCarriers,
        values.market
      );
      if (carriers?.length) {
        return [{ title: 'Insurances provided by your employer', carriers }];
      } else {
        // Still nothing? Let's fall back to regular logic
      }
    }

    // sorting insuranceCarriers here bc Other insurance section does not get
    // sorted after visiting providers search result page.
    const [otherInsurance, ...sortedInsurances] = !insuranceCarriers
      ? []
      : sortBy(insuranceCarriers, ['id', 'name']);
    const sortedInsuranceCarriers = !otherInsurance
      ? []
      : [...sortedInsurances, otherInsurance];

    const otherInsuranceCarriersOutsideOfHeadwayNetworkForState = values.market
      ? sortedInsuranceCarriers
          .filter(
            (c: FrontEndCarrierRead) =>
              !isCarrierInHeadwayNetworkForState(c, values.market)
          )
          .sort((x, y) => {
            // ditto sort logic above
            const xSortVal = [x.parentFrontEndCarrierId ? 1 : 0, x.name];
            const ySortVal = [y.parentFrontEndCarrierId ? 1 : 0, y.name];
            return xSortVal > ySortVal ? 1 : 0;
          })
      : [];

    const parentInsuranceCarriersInHeadwayNetworkForState =
      insuranceCarriers && values.market
        ? insuranceCarriers
            .filter(
              (carrier) =>
                !Number.isFinite(carrier.parentFrontEndCarrierId) &&
                isCarrierInHeadwayNetworkForState(carrier, values.market)
            )
            .sort((x, y) => {
              return x.name.localeCompare(y.name);
            })
        : [];

    const subInsuranceCarriersInHeadwayNetworkForState =
      insuranceCarriers && values.market
        ? insuranceCarriers
            .filter(
              (carrier) =>
                Number.isFinite(carrier.parentFrontEndCarrierId) &&
                isCarrierInHeadwayNetworkForState(carrier, values.market)
            )
            .sort((x, y) => {
              return x.name.localeCompare(y.name);
            })
        : [];

    const result: { title: string; carriers: FrontEndCarrierRead[] }[] = [];

    const currentMarket =
      values.market && unitedStatesDisplayNames[values.market!];

    if (parentInsuranceCarriersInHeadwayNetworkForState.length > 0) {
      result.push({
        title: currentMarket ? `Popular in ${currentMarket}` : '',
        carriers: parentInsuranceCarriersInHeadwayNetworkForState,
      });
    }

    // We always want to display a section for the current state; even if there
    // are none.
    result.push({
      title: currentMarket
        ? `All insurances we work with in ${currentMarket}`
        : '',
      carriers: subInsuranceCarriersInHeadwayNetworkForState,
    });

    // And other insurance …for some reason?
    result.push({
      title: 'Other insurances',
      carriers: otherInsuranceCarriersOutsideOfHeadwayNetworkForState,
    });

    return result;
  }, [insuranceCarriers, values.market, employerRestrictions]);

  const commonProps = {
    testId: 'compass-insurance',
    label: 'Insurance carrier',
    placeholder: 'Not sure? Skip for now.',
    disabled: !values.market,
    open,
    onOpen,
    onClose,
    onSelect: setSelectedInsurance,
    onClick: () => {
      if (!values.market) {
        // if a state has not been selected yet, open the dropdown for the states instead
        openStatesDropdown();
      }
    },
  } as const;

  // Note that we have to force the UI into CustomFieldSelectWithLabel when we
  // have employer restrictions, because the AutocompleteDropdown does not
  // support control over its value.
  if (!typesearchEnabled || employerRestrictions) {
    const options = optionSections.flatMap(({ title, carriers }) => [
      {
        text: title,
        key: title,
        disabled: true,
        label: '',
        value: '',
      },
      ...(carriers.length > 0
        ? carriers.map(({ id, name }) => ({
            key: id,
            value: id,
            text: name,
            label: name,
          }))
        : [
            {
              disabled: true,
              value: NO_OPTIONS_FOUND_VALUE,
              key: `no-options-for-${title}`,
              text: 'None',
              label: '',
            },
          ]),
    ]);

    return (
      <CustomFieldSelectWithLabel
        {...commonProps}
        name="insurance"
        values={values}
        dirty={dirty}
        value={selectedInsurance}
        setFieldValue={setFieldValue}
        initialValue={undefined}
        shareLater={!employerRestrictions} // Inserts the "I'll share later" row
        renderValue={(id: string) =>
          insuranceCarriers
            ? insuranceCarriers.find((item) => item.id === parseInt(id))?.name
            : ''
        }
        options={options}
        css={{
          width: `200px !important`,
          marginRight: `0.625rem`,
          marginLeft: legacyTheme.space.xs,
          paddingTop: legacyTheme.space.xs,
          paddingBottom: legacyTheme.space.xs,
          [legacyTheme.media.smallDown]: {
            width: `100% !important`,
            alignSelf: 'stretch',
            marginLeft: 0,
            paddingTop: legacyTheme.space.sm,
            paddingBottom: legacyTheme.space.sm,
          },
          '.Mui-disabled': {
            '.label': {
              '-webkit-text-fill-color': legacyTheme.color.black,
              span: {
                color: legacyTheme.color.black,
              },
            },
            '-webkit-text-fill-color': `${legacyTheme.color.textGray} !important`,
            '.MuiSvgIcon-root': {
              color: 'rgba(0, 0, 0, 0.54)',
            },
          },
        }}
        inputStyle={customSelectStyle}
      />
    );
  } else {
    const options = [
      {
        value: 'share_later',
        key: undefined,
        text: "I'll share later",
        label: '',
      },
      ...optionSections.flatMap(({ title, carriers }) => [
        {
          text: title,
          key: title,
          disabled: true,
          label: '',
          value: '',
        },
        ...(carriers.length > 0
          ? carriers.map(({ id, name }) => ({
              key: id,
              value: name, // NOTE: this dropdown uses the /name/ as a value!
              text: name,
              label: name,
            }))
          : [
              {
                disabled: true,
                value: NO_OPTIONS_FOUND_VALUE,
                key: undefined,
                text: 'None',
                label: '',
              },
            ]),
      ]),
    ];

    return (
      <DropdownAutocomplete
        {...commonProps}
        isFormFocused={isFormFocused}
        key={selectedState || ''}
        options={options}
        trimBeforeFilteringOptions={true}
      />
    );
  }
};

export default withStores(GetStartedContainer);
